<template>
  <div class="slideshow">
    <tiny-header title="截图欣赏" subtitle="Gallery" color="#ffc15e"></tiny-header>
    <vue-flux
        :options="vfOptions"
        :images="vfImages"
        :transitions="vfTransitions"
        :captions="vfCaptions"
        ref="slider"
        class="slider"
    >

      <template v-slot:preloader>
        <flux-preloader />
      </template>

      <template v-slot:caption>
        <flux-caption />
      </template>

      <template v-slot:controls>
        <flux-controls />
      </template>

      <template v-slot:index>
        <flux-index />
      </template>
    </vue-flux>
  </div>
</template>

<script>
import TinyHeader from "@/components/tiny/TinyHeader";
import {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxIndex,
  FluxPreloader
} from 'vue-flux';

export default {
  components: {
    VueFlux,
    FluxCaption,
    FluxControls,
    FluxIndex,
    FluxPreloader,
    TinyHeader
  },

  data: () => ({
    vfOptions: {
      autoplay: true
    },
    vfImages: [
      'https://s1.ax1x.com/2022/07/05/jtweN6.jpg',
      'https://s1.ax1x.com/2022/07/05/jtwZAx.jpg',
      'https://s1.ax1x.com/2022/05/26/XALRqH.jpg',
      'https://s1.ax1x.com/2022/05/26/XAL2se.jpg',
      'https://s1.ax1x.com/2022/05/26/XALgMD.jpg',
      'https://s1.ax1x.com/2022/05/26/XAL6xO.jpg',
      'https://s1.ax1x.com/2022/05/26/XALyRK.jpg',
      'https://s1.ax1x.com/2022/05/26/XALsG6.jpg',
      'https://s1.ax1x.com/2022/05/26/XALrPx.jpg',
      'https://s1.ax1x.com/2022/05/26/XALBI1.jpg',
      'https://s1.ax1x.com/2022/05/26/XAL0aR.jpg',
      'https://s1.ax1x.com/2022/05/26/XALwZ9.jpg',
      'https://s1.ax1x.com/2022/05/26/XALaqJ.jpg',
      'https://s1.ax1x.com/2022/05/26/XALUr4.jpg',
      'https://s1.ax1x.com/2022/05/26/XALNMF.jpg',
      'https://s1.ax1x.com/2022/05/26/XALYxU.jpg',
      'https://s1.ax1x.com/2022/05/26/XALJ2T.jpg',
      'https://s1.ax1x.com/2022/05/26/XALGGV.jpg',
      'https://s1.ax1x.com/2022/05/26/XAL8P0.jpg',
      'https://s1.ax1x.com/2022/05/26/XAL15q.jpg',
    ],
    vfTransitions: [ 'slide' ],
    // vfCaptions: [
    //   'Caption for image 1',
    //   'Caption for image 2',
    //   'Caption for image 3',
    // ],
  }),
}

</script>

<style scoped lang="scss">
.slideshow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 50px;
  background: var(--background-dark);
}

.slider {
  margin-top: 1em;
  width: 100%;
  max-width: 81vw;
}







</style>

<template>
  <div class="section__intro">
    <div class="intro_indicator">
      <div class="indicator__wrapper" ref="wrapper">
        <indicator :text="ele" @click.native="jump(i)" :class="{clicked: clickedIdx===i}"
                   v-for="(ele,i) in indicators" :key="i"></indicator>
        <div class="timeline" ref="timeline"></div>
      </div>
    </div>
    <div class="intro_container">
      <p class="content">
        {{ text[clickedIdx] }}
      </p>
    </div>
  </div>
</template>

<script>
import Indicator from "@/components/tiny/Indicator";

export default {
  name: "Intro",
  components: {Indicator},
  data() {
    return {
      indicators: ["砂糖服是个怎样的服务器", "简史", "鸣谢"],
      text: [
        "砂糖服是基于原版生存玩法全面革新的数据包+插件混合服务器，注重探索的玩法，和玩家交互。广受玩家好评。\n" +
        "希望你能重拾初次探索MC游戏世界的那份激动的心情一起加入我们的冒险。在这里无论你是想要名扬一方，\n" +
        "建立属于你的王国，还是隐居养老颐养天年，又或者浪迹天涯游历四方，你都能找到属于你的归宿。",
        "砂糖 Citrus 项目起源于2021年末，并作为数据包服务器为玩家们尝鲜的社区服务器。\n" +
        "此后我们逐步发展为独立完整的全面的群组服务器。砂糖服始终在不断更新以及调整游戏内容来给玩家们新鲜充实的体验。\n" +
        "在一周目期间平行开发与几轮测试后最终在2022年五月，我们更新至1.18.2并开启了全面升级的二周目。\n" +
        "二周目突破了原有版本的技术限制，预计将成为长久运行的常驻周目并长期维护。\n" +
        "希望五湖四海的朋友们能够相聚于此，我和我的团队祝大家体验到当初第一次玩MC的美好！",
        "感谢所有支持砂糖服的大家！ 2022年让我们继续加油！"
      ],
      clickedIdx: 0,
    }
  },
  methods: {
    jump: function (idx) {
      this.clickedIdx = idx;
    }
  }
}
</script>

<style scoped lang="scss">
.section__intro {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--cirtus-background);
}

.intro_indicator {
  width: auto;
  height: 100px;
  margin-top: 40px;
}

.indicator__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.timeline {
  position: absolute;
  width: 87vw;
  margin: auto;
  border: 2px solid var(--background-gray-50);
  z-index: 0;
}

.intro_container {
  padding: 20px;
  box-sizing: border-box;

  .content {
    font-family: "Poppins", Helvetica, sans-serif ;
    font-size: 1.2rem;
    line-height: 2.4rem;
    max-width: 50em;
    font-weight: bold;
    color: var(--citrus-text-dark);
  }

  .content:first-letter {
    font-size: 2rem;
  }
}

.clicked {
  border: 3px solid var(--background-red);
  background: var(--background-red-t);
}

</style>

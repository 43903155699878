<template>
  <div class="shiny_block flex-col-aj shrink">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ShinyBlock"
}
</script>

<style scoped lang="scss">
.shiny_block {
  padding: 1em;
  border-radius: 1em;
  box-sizing: border-box;
}

//.shrink, .inner_shadow {
//  transition: all .15s ease-in-out;
//}
//
//.outer_shadow {
//  transition: all .15s linear 50ms;
//}

//.shrink:hover {
//  transform: scale(0.95);
//}

//.outer_shadow:hover {
//  filter: drop-shadow(0.5rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2));
//}
//
//.inner_shadow:hover {
//  box-shadow: inset -7px -6px 20px 4px #cfcfcf;
//}
//
//.inner_shadow_dark:hover {
//  box-shadow: inset -7px -6px 20px 4px #2e2e2e;
//}
//
//.inner_shadow_very_dark:hover {
//  box-shadow: inset -7px -6px 20px 4px #1c1c1c;
//}

</style>

<template>
  <div class="dev">
    <tiny-header title="我们的团队" subtitle="Team&Dev" color="#24272b"></tiny-header>
    <div class="staff_view">
      <shiny-block class="inner_shadow" v-for="ele in staff" :key="ele.name">
        <img class="avatar outer_shadow" :src="ele.avatar" alt="avatar"/>
        <h3>{{ ele.username }}</h3>
        <h4>{{ ele.desc }}</h4>
      </shiny-block>
    </div>
    <p class="bold-500 margin-vec-10">长期招收开发者、宣传者、管理者</p>
    <p class="margin-vec-10">如果你有意向加入我们，展现你的才华或者想与我们一同努力</p>
    <p class="margin-vec-10">或者作为友服想要了解我们的技术与合作</p>
    <dark-button text="了解更多" href="https://www.yuque.com/tqwan0/ktcw92/ruogob"></dark-button>
  </div>
</template>

<script>
import TinyHeader from "@/components/tiny/TinyHeader";
import ShinyBlock from "@/components/tiny/ShinyBlock";
import DarkButton from "@/components/tiny/DarkButton";

export default {
  name: "Dev",
  components: {DarkButton, ShinyBlock, TinyHeader},
  data() {
    return {
      staff: [{
        avatar: "https://crafatar.com/avatars/36b33b9e-29dd-45bc-9ab6-fcfbe0deebb3",
        username: "li_shi",
        desc: "玩家代表"
      }, {
        avatar: "https://crafatar.com/avatars/9748a077-8130-4722-96d4-8ac490878ece",
        username: "Wtq_",
        desc: "服主"
      }, {
        avatar: "https://crafatar.com/avatars/9c9f90be-7d7a-48ab-bb7e-a8cdc95d0582",
        username: "PengYn123",
        desc: "建筑师"
      }, {
        avatar: "https://crafatar.com/avatars/762ea24f-9c61-46bc-b66d-4e7c98cd3bbe",
        username: "GenShinN1",
        desc: "管理员"
      },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.dev {
  alignment: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 50px;
  background: var(--cirtus-background);
}

.staff_view {
  alignment: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1em 0.5em;

  .shiny_block {
    width: 18em;
    background: var(--background-staff);
    margin: 1em;

    img {
      margin-top: 1em;
      border-radius: 0.25em;
    }

    h3 {
      margin: 10px 0;
      color: #525252;
      font-size: 1.5rem;
      font-family: "Poppins", serif;
    }

    h4 {
      font-size: 1.2rem;
      margin: 5px 0;
    }
  }
}

</style>

<template>
  <div class="home">
    <a name="home"><home-header id="home" /></a>
    <NavigationBar></NavigationBar>
    <a name="announcement"><anno id="announcement" /></a>
    <a name="introduction"><intro id="introduction" /></a>
    <a name="slideshow"><slideshow id="slideshow" /></a>
    <a name="promotion"><promotion id="promotion" /></a>
    <a name="about-us"><dev id="about-us" /></a>
    <a name="sponsors"><sponsor id="sponsors" /></a>
    <back-top id="back-top" />
  </div>
</template>

<script>
import Intro from "@/components/Intro";
import Feature from "@/components/Feature";
import HomeHeader from "@/components/HomeHeader";
import Dev from "@/components/Dev";
import Sponsor from "@/components/Sponsor";
import Anno from "@/components/Anno";
import BackTop from "@/components/tiny/BackTop";
import Slideshow from "@/components/Slideshow";
import NavigationBar from "@/components/NavigationBar";
import Promotion from "@/components/Promotion";
export default {
  name: "Home",
  components: {
    NavigationBar,
    BackTop,
    Anno,
    Sponsor,
    Dev,
    HomeHeader,
    Feature,
    Promotion,
    Intro,
    Slideshow,
  },
};
</script>

<style scoped lang="scss">
.home {
  height: 100%;
}
</style>

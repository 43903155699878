<template>
  <div class="feature">
    <tiny-header title="特色" subtitle="Feature" color="#ffc15e"></tiny-header>
    <div class="container">
      <div class="scroll__container" ref="container" v-for="(ele,i) in text">
        <transition name="slide_in" @before-enter="push">
          <swing-card v-if="loaded&&isShownCard[i]" :class="{inversed: i%2===1,left:i%2===0,right:i%2===1}">
            <div class="feature__text" v-html="ele"></div>
          </swing-card>
        </transition>
        <br/>
      </div>
    </div>
  </div>
</template>

<script>
import TinyHeader from "@/components/tiny/TinyHeader";
import Velocity from "velocity-animate"
import SwingCard from "@/components/tiny/SwingCard";

export default {
  name: "Feature",
  components: {SwingCard, TinyHeader},
  mounted() {
    this.loaded = true;
    this.$refs.container.forEach(() => {
      this.isShownCard.push(false)
    })
    window.addEventListener('scroll', () => {
      this.nowWindowTop = document.body.scrollTop || document.documentElement.scrollTop
      this.$refs.container.forEach((el, idx) => {
        const rect = el.getBoundingClientRect()
        console.log(rect.top + window.scrollY - window.visualViewport.height <= this.nowWindowTop)
        this.isShownCard[idx] = (rect.top + window.scrollY - window.visualViewport.height <= this.nowWindowTop)
      })
    })
  },
  data() {
    return {
      loaded: false,
      nowWindowTop: 0,
      isShownCard: [],
      text: [
        "<strong>壮阔瑰丽，一个熟悉又陌生的世界世界<strong>" +
        "生成采用了数据包生成的群系与地形，并搭配新版本大洞穴与大量自然生成的随机结构。 超过两百种迥然不同的生物群系，总有能让你发挥建筑灵感的地方做为你未来的美丽家园。 数十余种各式各样的副本挑战等待你发现，寻找失落的遗迹来锻造强力的装备，亦或者你只是想要寻找一片能够落脚的村庄。\n" +
        "下界美丽而致命。猪灵与凋零骷髅的漫长血战，失落文明留下的核反应堆和能量管线，森严宏伟的猪灵城堡，都等待着你的发现。 唤醒强大的炙炎领主，解开下界尘封的秘密，当你做好准备面对他时，夺取非凡的战利品。",
        "<strong>家园系统</strong>" +
        "砂糖服使用了高级版城镇插件，刚进入服务器你可能会发现大家都有自己所属的城镇。 你可以选择加入一个城镇或者赚足够的钱来自己创建一个。当然你也可以深耕于资源区作为一个自由自在的无邦者。但不论何时，您只能加入一个城镇。 成为一个城镇的主人就会有管理城镇的权利，相应的你也有着维持收支与规划地皮的责任。 请记住，做好一个管理者需要的不仅仅是智慧也需要能与人交流的能力。城镇所有的成员越多，你城镇的影响力也就会越大，你可以获得更多的领土。 当你觉得是时候了，就可以带领你的人民加入一个王国或者干脆自立为王。 王国有一个或多个城镇组成，国王有着统筹疆域内所有城镇的权力同时也可以与其他国家进行联盟与战争。 王国同样受成员数量影响你国家的繁荣度，通常来说你能联合的城镇与国家越多，你的实力与国库就会越充盈。 不论你是想用爱与善良感化所有人，还是用权谋与手段成为一个枭雄，砂糖服完全尊重你的选择。 城镇系统将会满足你所有的中二幻想，我们也鼓励你这样做，毕竟角色扮演真的太好玩啦。"
      ]
    }
  },
  methods: {
    push: function (el) {
      console.log("ok")
      Velocity(el, {translateX: '0px'}, {
        duration: 6000,
        easing: "swing"
      })
    },
  }
}
</script>

<style scoped lang="scss">
.feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 10em;
  background: var(--background-dark);
}

.container {
  .left {
    transform: translateX(-100%);
  }

  .right {
    transform: translateX(100%);
  }
}

.scroll__container {
  br {
    display: block;
    width: 50%;
    height: 1px;
    background: #ffffff;
  }

  .feature__text {
    color: var(--cirtus-text);
  }
}

</style>

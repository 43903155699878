import axios from "axios";

const baseUrl = ""

export const get = (url: string, params?: any) => {
    return axios.get(baseUrl + url, {
        params: params,
    }).then(res => res.data);
}

export const post = (url: string, body?: any) => {
    return axios.post(baseUrl + url, body).then(res => res.data);
}

export const anyGet = (url: string, params?: any) => {
    return axios.get(url, {
        params: params,
    }).then(res => res.data);
}

<template>
  <div class="sponsor">
    <tiny-header title="支持我们" subtitle="Support us" color="#ffc15e"></tiny-header>
    <div class="sponsor_view">
      <shiny-block class="inner_shadow_dark" v-for="ele in sponsor" :key="ele.name">
        <div class="wrapper">
          <img class="avatar outer_shadow" :src="ele.logo" alt="avatar"/>
        </div>
        <h3>{{ ele.name }}</h3>
        <dark-button text="查看" :href="ele.href"></dark-button>
      </shiny-block>
    </div>
  </div>
</template>

<script>
import TinyHeader from "@/components/tiny/TinyHeader";
import ShinyBlock from "@/components/tiny/ShinyBlock";
import DarkButton from "@/components/tiny/DarkButton";

export default {
  name: "Sponsor",
  components: {DarkButton, ShinyBlock, TinyHeader},
  data() {
    return {
      sponsor: [{
        logo: require(`@/assets/misc/mcmod.gif`),
        name: "MCMOD",
        href: "https://play.mcmod.cn/sv20186399.html"
      }, {
        logo: "https://www.mcbbs.net/template/mcbbs/image/logo_sc.png",
        name: "MCBBS",
        href: "https://www.mcbbs.net/thread-1286049-1-1.html"
      }]
    }
  }
}
</script>

<style scoped lang="scss">
.sponsor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 50px;
  background: var(--background-dark);
  alignment: center;
}

.sponsor_view {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 1em 0.5em;

  .shiny_block {
    width: 20em;
    height: 20em;
    margin: 1em;
    background: var(--background-light-dark);

    .wrapper {
      display: flex;
      align-items: center;
      height: 180px;
    }

    h3 {
      margin: 10px 0;
      color: #ffffff;
      font-size: 1.5rem;
      font-family: "Poppins", serif;
    }
  }
}

</style>

<template>
  <div class="promotion">
    <tiny-header title="精彩视频" subtitle="Citrus TV" color="#ffc15e"></tiny-header>
    <div class="videos">
      <iframe
          src="https://player.bilibili.com/player.html?aid=729174360&bvid=BV1TS4y147VB&cid=793522125&page=1&as_wide=1&high_quality=1&danmaku=0"
          allow="fullscreen"
      />
      <iframe
          src="https://player.bilibili.com/player.html?aid=856781282&bvid=BV1kV4y1x7Y8&cid=797592026&page=1&as_wide=1&high_quality=1&danmaku=0"
          allow="fullscreen"
      />
      <iframe
          src="https://player.bilibili.com/player.html?aid=210602392&bvid=BV1Da411q7xB&cid=482377225&page=1&as_wide=1&high_quality=1&danmaku=0"
          allow="fullscreen"
      />
    </div>

  </div>
</template>

<script>
import TinyHeader from "@/components/tiny/TinyHeader";

export default {
  name: "Promotion",
  components: {TinyHeader},
}
</script>

<style scoped lang="scss">

.promotion {
  alignment: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 50px;
  background: var(--background-dark);
}

.videos {
  alignment: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  width: 100%;
  max-width: 81vw;

  iframe {
    width: 40em;
    aspect-ratio : 1 / 0.6;
    margin: 1em;
  }
}

</style>
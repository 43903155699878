<template>
  <div class="header">
    <h1 class="title" :style="{color: color}">{{ title }}</h1>
    <p class="supplement" :style="{color: color}">{{ subtitle }}</p>
  </div>
</template>

<script>
export default {
  name: "TinyHeader",
  props: ["color", "title", "subtitle"]
}
</script>

<style scoped lang="scss">
.header {
  width: max-content;

  .title {
    font-size: 4rem;
    text-align: center;
    border-bottom: 2px solid var(--cirtus-text-green);
  }

  .supplement {
    width: 100%;
    padding: 2px 0;
    text-align: center;
    text-indent: 8px;
    letter-spacing: 8px;
    font-family: Helvetica, serif;
  }
}
</style>

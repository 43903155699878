<template>
  <a class="button__dark" :href="href">{{ text }}</a>
</template>

<script>
export default {
  name: "DarkButton",
  props: ["text", "href"]
}
</script>

<style scoped lang="scss">
.button__dark {
  width: fit-content;
  letter-spacing: 0.5em;
  text-indent: 0.5em;
  text-decoration: none;
  margin: 10px 0;
  padding: 10px 25px;
  border-radius: 0.2em;
  background: var(--background-dark);
  text-align: center;
  font-family: "Minecraft Regular", "像素字体", Helvetica, sans-serif;
  color: #ffffff;
  font-weight: lighter;
  cursor: pointer;
  transition: all ease-in-out .2s;
}

.button__dark:hover {
  background: var(--cirtus-text-green);
}

</style>

<template>
  <div class="swing_card" :class="{inverse: inverse}">
    <div class="text">
      <slot></slot>
    </div>
    <div class="pic">
      <img src="../../assets/logo.png" alt="slide">
    </div>
  </div>
</template>

<script>
export default {
  name: "SwingCard",
  props: {
    inverse: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped lang="scss">
.swing_card {
  display: flex;
  align-items: center;
}

.inversed {
  flex-direction: row-reverse;
}

</style>

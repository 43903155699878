<template>
  <ui>
    <li><a href="#home">官网首页</a></li>
    <li><a href="https://www.yuque.com/tqwan0/ktcw92">砂糖百科</a></li>
    <li><a href="https://new.citrusmc.com:25581">玩家统计</a></li>
    <li><a href="https://new.citrusmc.com:25582/">在线地图</a></li>

  </ui>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
ui li {
  list-style: none;
  padding: 0px;
}
ui {
  max-height: 8em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--cirtus-background);
  width: 100%;
}
li {
  display: inline-block;
  width: 26%;
  height: 80%;
  margin: 1em;
  font-family: Helvetica, serif;
  font-size: 3vh;
  text-align: center;
  line-height: 1.7em;
  font-weight: 1000;


  a {
    color: var(--cirtus-text-dark-green);
    transition-duration: 0.5s;
  }
  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
    color: var(--background-red);
  }
  a:active {
    text-decoration: none;
  }
}
</style>

<template>
  <div
    class="back_top"
    :class="{ back_top_shown: isOnTop }"
    @click="backToTop"
    @mouseover="isMoverTop = true"
    @mouseout="isMoverTop = false"
  >
    <a
      href="https://www.yuque.com/tqwan0/ktcw92"
      :class="{ back_top_ment: isMoverTop, back_top_out: !isMoverTop }"
    >
      百科
    </a>
    <a
      href="https://new.citrusmc.com:25581"
      :class="{ back_top_ment: isMoverTop, back_top_out: !isMoverTop }"
    >
      统计
    </a>
    <a
      href="https://new.citrusmc.com:25582/"
      :class="{ back_top_ment: isMoverTop, back_top_out: !isMoverTop }"
    >
      地图
    </a>
  </div>
</template>

<script>
export default {
  name: "BackTop",
  mounted() {
    window.addEventListener("scroll", () => {
      const top = Math.floor(
        document.body.scrollTop || document.documentElement.scrollTop
      );
      this.isOnTop = top > 0;
    });
  },
  data() {
    return {
      isOnTop: false,
      isMoverTop: false,
    };
  },
  methods: {
    backToTop: function () {
      const timer = setInterval(() => {
        let top =
          Math.floor(
            document.body.scrollTop || document.documentElement.scrollTop
          ) - 50;
        document.body.scrollTop = document.documentElement.scrollTop = top;
        if (top <= 0) clearInterval(timer);
      }, 8);
    },
  },
};
</script>

<style scoped lang="scss">
.back_top {
  position: fixed;
  bottom: -200px;
  right: 40px;
  width: 60px;
  height: 60px;
  transition: all ease 0.3s;
  background: var(--background-light-dark);
  border-radius: 50%;
  border: 2px solid var(--background-staff);
  cursor: pointer;
  z-index: 1000;
}

.back_top {
  a {
    display: none;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    color: rgba(255, 255, 255, 0);
    text-align: center;
    line-height: 60px;
  }

  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none;
    color: var(--cirtus-text);
  }
  a:active {
    text-decoration: none;
  }
}
.back_top .back_top_ment {
  display: block;
  background-color: var(--background-light-dark);
  border: 2px solid var(--background-staff);
  color: rgba(255, 255, 255, 1);
}
.back_top .back_top_ment:nth-of-type(1) {
  display: block;
  transition: 0.5s;
  top: -65px;
}
.back_top .back_top_ment:nth-of-type(2) {
  display: block;
  transition: 0.5s;
  left: -65px;
}
.back_top .back_top_ment:nth-of-type(3) {
  display: block;
  transition: 0.5s;
  left: -65px;
  top: -65px;
}

.back_top .back_top_out:nth-of-type(1) {
  display: block;
  transition: 0.5s;
  top: 0px;
}
.back_top .back_top_out:nth-of-type(2) {
  display: block;
  transition: 0.5s;
  left: 0px;
}
.back_top .back_top_out:nth-of-type(3) {
  display: block;
  transition: 0.5s;
  left: 0px;
  top: 0px;
}

.back_top_shown {
  bottom: 40px;
}

.back_top:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: calc(50% - 3px);
  left: calc(50% - 7px);
  transform: rotate(-135deg);
  border-bottom: 3px solid var(--cirtus-background);
  border-right: 3px solid var(--cirtus-background);
}

</style>

<template>
  <div class="main" :class="bg_class">
    <div class="header0 clearfix">
      <!--      <div class="filter"></div>-->
      <div class="content">
        <img class="logo slowly" src="../assets/logo.png" alt="logo" />
        <h1 class="title">砂糖 <span class="title-hotfix">CITRUS!</span></h1>
        <p>
          服务器内正在有
          <strong class="online_player">{{ onlinePlayer }}</strong>
          位玩家正在玩耍呢！
        </p>
        <p>点击下面的地址🔗加入游戏吧</p>
        <p>
          👉🏻&nbsp;<span class="server_ip" @click="copy" ref="server_ip"
            >citrusmc.com</span
          >&nbsp;👈🏻
        </p>
        <shiny-block class="inner_shadow_very_dark">
          <div class="pinned_header">
            <i class="fas fa-thumbtack"></i>
            <span>置顶须知</span>
          </div>
          <div class="pinned_text" v-html="pinned_text"></div>
          <a
            href="https://qm.qq.com/cgi-bin/qm/qr?k=MCqeBMVFau38zfUkDC7EqctyLDKmTx4A&jump_from=webapi"
          >
            <img
              class="clickable-img"
              src="../assets/misc/qun.png"
              alt="QR_Code"
              width="100em"
            />
          </a>
        </shiny-block>
      </div>
      <div id="banner" class="bounce">
        <div class="content">二周目 火热开荒中 真的很有趣</div>
      </div>
    </div>
  </div>
</template>

<script>
import { anyGet } from "@/api/base";
import ShinyBlock from "@/components/tiny/ShinyBlock";

export default {
  name: "HomeHeader",
  components: { ShinyBlock },
  mounted() {
    // background image
    const date = new Date();
    if (date.getHours() >= 7 && date.getHours() < 18) {
      // day
      this.bg_class = "day";
    } else {
      // night
      this.bg_class = "night";
    }
    // ok,done
    anyGet("https://mcapi.us/server/status", {
      port: 25565,
      ip: "citrusmc.com",
    }).then((res) => {
      if (res.status === "success") {
        this.onlinePlayer = res.players.now;
      }
    });
  },
  data() {
    return {
      bg_class: "",
      onlinePlayer: "?",
      isStopCopy: false,
      pinned_text:
        "服务器版本：1.9 - 1.18.2*\n" +
        "正版、基岩版可以直接体验\n" +
        "离线玩家请<a style='color: #ffffff' href='https://www.yuque.com/tqwan0/ktcw92/gutptq'>点击这里</a>来了解如何加入\n" +
        "\n" +
        "我们目前仍在积极开发，并不代表最终品质。\n" +
        "如遇到BUG或者疑难问题，请与我们取得联系。",
    };
  },
  methods: {
    copy: function () {
      if (this.isStopCopy) return;
      this.isStopCopy = true;
      const box = this.$refs.server_ip;
      const ip = box.innerText;
      const range = document.createRange();
      range.selectNode(box);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy"); // expired
      window.getSelection().removeAllRanges();
      box.innerText = "已经复制到了剪贴板！";
      setTimeout(() => {
        box.innerText = ip;
        this.isStopCopy = false;
      }, 1500);
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.day {
  background: url("~@/assets/background/day.jpeg");
  background-size: cover;
}

.night {
  background: url("~@/assets/background/night.jpeg");
  background-size: cover;
}

.header0 {
  position: relative;
  min-width: 61%;
  border-radius: 10px;
}

.filter {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 0.25em;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: 0;
}

.content {
  position: relative;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #ffffff;
  z-index: 2022;
  font-size: 1em;
  font-family: "Poppins", Helvetica, sans-serif;

  .logo {
    margin-top: 1.2em;
    max-width: 25%;
  }

  .title {
    margin: 0.3rem;
    font-family: "Minecraft Regular", "像素字体", Helvetica, sans-serif;
    font-size: 5rem;
    color: var(--cirtus-text);
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff,
      -1px 1px 0 rgba(255, 193, 94, 0.75), 1px 1px 0 rgba(255, 193, 94, 0.75);
  }

  .title-hotfix {
    position: relative;
    top: 0.3rem;
  }

  .shiny_block {
    justify-content: normal;
    margin: 1em;
    min-width: 61%;
    background: var(--background-very-dark);
    backdrop-filter: blur(4px);
  }

  .pinned_header {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;
  }

  .pinned_text {
    word-wrap: break-word;
    white-space: pre-wrap;
    text-align: center;
    margin-bottom: 15px;
    padding: 1em 2em;
  }

  .clickable-img:hover {
    transform: scale(1.05);
    filter: drop-shadow(0.5rem 0.5rem 0.25rem rgba(0, 0, 0, 0.2));
  }

  @media screen and (max-width: 1380px) {
    .title {
      font-size: 3rem;
    }
  }

  @media screen and (max-width: 1030px) {
    .title {
      font-size: 2rem;
    }
  }
}

.online_player {
  font-family: "Minecraft Regular", "像素字体", Helvetica, sans-serif;
  color: var(--cirtus-text);
}

.server_ip {
  font-family: "Minecraft Regular", "像素字体", Helvetica, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--cirtus-text);
  //text-decoration: underline;
  cursor: pointer;
}

#banner {
  position: absolute;
  right: -25%;
  top: 90px;
  z-index: 2023;

  .content {
    //height: 100%;
    width: max-content;
    max-width: 300px;
    color: var(--text-yellow);
    font-family: "Minecraft Regular", "像素字体", Helvetica, sans-serif;
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
    text-shadow: 3px 3px #3f3f00;
    transform: rotate(-30deg) translateX(-50%);
    transform-origin: left top;
  }
}

.slowly {
  animation: animate ease-in-out 3s infinite alternate;
  @keyframes animate {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.95);
    }
  }
}

.bounce {
  animation: animate ease-in-out 0.27s infinite alternate;
  @keyframes animate {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.95);
    }
  }
}
</style>

<template>
  <div class="indicator">
    <span>{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "Indicator",
  props: ["text"]
}
</script>

<style scoped lang="scss">
.indicator {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 10vw;
  border: 3px solid var(--cirtus-text-green);
  background: var(--cirtus-text-green-t);
  z-index: 1;
  cursor: pointer;
  transition: all .2s linear;

  span {
    position: absolute;
    width: max-content;
    max-width: 17vw;
    text-align: center;
    left: 50%;
    top: 150%;
    transform: translateX(-50%);
    font-weight: 500;
  }
}

</style>

<template>
  <div class="anno">
    <tiny-header
      title="新闻公告"
      subtitle="Announcement"
      color="#ffc15e"
    ></tiny-header>
    <div class="container flex-col-aj">
      <strong class="content" v-html="text"></strong>
      <dark-button text="群文件下载" href="https://qm.qq.com/cgi-bin/qm/qr?k=MCqeBMVFau38zfUkDC7EqctyLDKmTx4A&jump_from=webapi"/>
    </div>
  </div>
</template>

<script>
import TinyHeader from "@/components/tiny/TinyHeader";
import DarkButton from "@/components/tiny/DarkButton";

export default {
  name: "Anno",
  components: { DarkButton, TinyHeader },
  data() {
    return {
      text:
        "现已推出适合所有人使用的 香橼联机优化整合包\n" +
        "不仅仅适用于砂糖服，能为你在广泛的原版生存服中获得极致体验\n" +
        "包含性能优化、光影、小地图、投影等一些列辅助功能\n" +
        "并添加了内置语音、物理破坏、音效、环境粒子增强等沉浸感模组",
    };
  },
};
</script>

<style scoped lang="scss">
.anno {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 50px;
  background: var(--background-dark);
}

.container {
  justify-content: normal;
  min-width: 50%;
  min-height: 312px;
  margin-top: 50px;
  padding: 2em 40px;
  box-sizing: border-box;
  background-repeat: repeat-x;
  background-size: cover;
  background-image: url("~@/assets/misc/sign.png");
  border-radius: 0.2rem;

  .content {
    width: 100%;
    text-align: center;
    color: var(--citrus-text-dark);
    word-wrap: break-word;
    white-space: pre-wrap;
    font-family: "Minecraft Regular", 像素字体, sans-serif, Serif;
    font-size: 1.4rem;
    line-height: 3rem;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff,
      1px 1px 0 #fff;
  }

  .link-no-style {
    text-decoration: none;
  }

  .button__dark {
    margin-top: 1em;
  }
}
</style>
